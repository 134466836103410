export const PRODUCTS = [
    { name: "7th Floor, Awfis, Mantri Commerce Bannerghatta Road, BTM 2nd Stage Bangalore – 560076", link: "#" },
  ];
//   export const COMPANY = [
//     { name: "Diversity & inclusion", link: "#" },
//     { name: "About us", link: "#" },
//     { name: "Press", link: "#" },
//     { name: "Customer Stories", link: "#" },
//     { name: "Online communities", link: "#" },
//   ];
  
 export const Icons = [
  { name: "facebook" },
  { name: "twitter" },
  { name: "instagram" },
  { name: "linkedin" }
];