// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 300px) and (max-width: 600px) {
.max-height{
  height:40vh !important;
}
}`, "",{"version":3,"sources":["webpack://./src/components/blogs/blog.css"],"names":[],"mappings":"AAAA;AACA;EACE,sBAAsB;AACxB;AACA","sourcesContent":["@media screen and (min-width: 300px) and (max-width: 600px) {\n.max-height{\n  height:40vh !important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
