// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-font-size{
    font-size: 3rem;
}

.bg-aclice{
    background-color: #f1f5f9;
}
@media screen and (min-width: 300px) and (max-width: 600px) {
    .text-font-size{
        font-size: 1.7rem;
    }
.text-zinc-font{
    font-size: 1rem;

}
}`, "",{"version":3,"sources":["webpack://./src/components/about/about.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI;QACI,iBAAiB;IACrB;AACJ;IACI,eAAe;;AAEnB;AACA","sourcesContent":[".text-font-size{\n    font-size: 3rem;\n}\n\n.bg-aclice{\n    background-color: #f1f5f9;\n}\n@media screen and (min-width: 300px) and (max-width: 600px) {\n    .text-font-size{\n        font-size: 1.7rem;\n    }\n.text-zinc-font{\n    font-size: 1rem;\n\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
